import { LatLngTuple, Map as MapClass } from 'leaflet';
import React, { ReactNode } from 'react';
import { Rectangle } from 'react-leaflet';
import { FullscreenViever } from "./FullscreenPicture"
import { Map, iPoint } from "./Map"

function GeneralMapApp({
    points, center, userPos, mapRefCallback, mapClickHandler,onMarkerClick, children, mapChildren, showGameBounds
}:{
    points: iPoint[],
    center : LatLngTuple,
    userPos?: LatLngTuple,
    mapRefCallback?: (ref:MapClass)=>void,
    mapClickHandler: (ev:any)=>void,
    onMarkerClick?:(ev:any)=>void,
    children?: ReactNode,
    mapChildren?: ReactNode,
    showGameBounds?: L.LatLngBounds | null 
}) {
    
    return (
      <div className="App">
          <FullscreenViever></FullscreenViever>
          <Map points={points} center={center} userPos={userPos} onClick={mapClickHandler} onMarkerClick={onMarkerClick} mapRefCallback={mapRefCallback}>
            <React.Fragment>
            {mapChildren}
            {showGameBounds ? <Rectangle bounds={showGameBounds} /> : null }
            </React.Fragment>
          </Map>
          <div className='InterfaceContainer'>
            {children}
          </div>
      </div>
    );
  }
  
  export default GeneralMapApp;