import { useEffect } from "react"
import { bounds, divIcon, latLngBounds, LatLngTuple, Map as MapClass } from 'leaflet';

function useWASDkeys(mapRef: React.MutableRefObject<MapClass | null>,userPos:LatLngTuple,onMove : (pos:LatLngTuple) => void, delta = 10){
    
    useEffect(()=>{
        document.body.addEventListener('keypress',onKeyPress)
        return ()=>document.body.removeEventListener('keypress',onKeyPress)
      })

      const onKeyPress = (ev:any) => {
        const key = ev.key;

        if(mapRef.current){
          const posInPixels = mapRef.current.latLngToContainerPoint(userPos)
          switch(key){
            case 'w': posInPixels.y -= delta; break;
            case 's': posInPixels.y += delta; break;
            case 'a': posInPixels.x -= delta; break;
            case 'd': posInPixels.x += delta; break;
          }
          const latlng = mapRef.current.containerPointToLatLng(posInPixels)
          const latlngtuple : LatLngTuple = [latlng.lat,latlng.lng]
          onMove(latlngtuple)
          //console.log(posInPixels,userPos)
        }
      }

}

export {useWASDkeys}