import { LatLngExpression, LatLngTuple, DivIcon, popup, divIcon,  Map as MapClass, icon, Icon, Point, LatLngBounds, latLngBounds } from 'leaflet'
import { memo, ReactNode, ReactPropTypes, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap, ZoomControl, useMapEvent, LayerGroup, Rectangle } from 'react-leaflet'
import {Popup as LeafletPopup, Marker as LeafletMarker} from 'leaflet'
import locationicon from '../assets/locationicon.svg'
import cameraicon from '../assets/cameraicon.svg'

const photoIcon = divIcon({className:"photoIcon",html:`<img src="${cameraicon}"/>`})



interface iPoint {
    localisation:LatLngTuple,
    id:string,
    type?:string,
    name?:string,
    icon?:Icon | DivIcon,
    isActive?:boolean,
    isAvailable?:boolean,
    Popup?: React.ReactNode
}


const userIcon = divIcon({className: "userOnMapIcon"})

const XMarker = (
    {point, onClick} : {
        point:iPoint,
        onClick?: (arg:any) => void
    } ) => {
    
    const markerRef = useRef<LeafletMarker | null>();
    const [markerReady, setMarkerReady] = useState(false);
    const [popupReady, setPopupReady] = useState(false)

    useEffect(() => {
        if (markerReady && popupReady && point.isActive) {
            setTimeout(()=>markerRef.current?.openPopup(),100)
        }
      }, [point,markerReady,popupReady]);//
  
    const getPopup = useCallback((point:iPoint)=>{
        if(point.isAvailable){
            return point.Popup ? <Popup>{point.Popup}</Popup> 
                : point.name ? <Popup>{point.name}</Popup>
                : null
        }
        if(!point.isActive){
            return null
        }
        return point.Popup ? <Popup autoClose={false} closeOnEscapeKey={false} closeOnClick={false} ref={()=>setPopupReady(true)}>{point.Popup}</Popup>
            : point.name ? <Popup>{point.name}</Popup> 
            : null
    },[])

    //eventHandlers={{click:onClick ?? (()=>null) }}
    //isActive && console.log('rendering marker ',isActive,point.id)

    const clickProxyHandler = (ev:any) => {
        if(!onClick) return;
        //[ev.latlng.lat, ev.latlng.lng] as LatLngTuple
        ev.latlng = {lat: point.localisation[0], lng: point.localisation[1]}
        onClick(ev)
    }


    return <Marker eventHandlers={onClick && {click:clickProxyHandler}} ref={r=>{markerRef.current = r; setMarkerReady(true)}} {...point?.type == 'photo' ? {icon:photoIcon} : {}} position = {point.localisation} >
        {getPopup(point)}
    </Marker>
}

const ClickOnMap = ({onClick}:{onClick:(ev:any)=>void }) => {
    const map = useMap()
    useMapEvent('click',(ev)=>{
        //console.log('MapClick ',ev.originalEvent.target.)
        onClick(ev) 
    })
    return null;
}

const MapRef = ({callback}:{callback:(m:MapClass)=>void}) => {
    const map = useMap()
    callback(map)
    return null
}

const Map = ({children,points,center,userPos,onClick,onMarkerClick,mapRefCallback}:{
    children?:ReactNode,
    points:iPoint[],
    center:LatLngExpression,
    userPos?:LatLngExpression,
    onClick?:(ev:any)=>void,
    onMarkerClick?:(ev:any)=>void,
    mapRefCallback?: (ref:MapClass)=>void
}) => {
    
    //const center : LatLngExpression = {lat: 52.25184771386281, lng: 20.9960406459868}
   
    const mapRef = useRef<MapClass | null>(null)

    //points[0].localisation
    //const [markers,setMarkers] = useState<LatLngExpression[]>([])

    return <MapContainer center={center} zoom={17} scrollWheelZoom={false} id="MapDiv" zoomControl={false}  >
        { mapRefCallback ? <MapRef callback={mapRefCallback}></MapRef> : null}
        { onClick ? <ClickOnMap onClick={onClick}></ClickOnMap> : null}
        <MapRef callback={ref=>mapRef.current=ref}></MapRef>
        <ZoomControl position="topright" />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {
        points.map(
            (point,i) => <XMarker point={point} key={i} onClick={onMarkerClick}></XMarker>
        )}
        {
           false && points.length ? <Rectangle
                  bounds={latLngBounds(points.map(p=>p.localisation))}
                  pathOptions={{color: "white"}}
            /> : null
        }
        <LayerGroup>
        { userPos!==undefined ? <Marker position={userPos} icon={userIcon}></Marker> : null }
        </LayerGroup>
        {children}
        <div className="leaflet-top leaflet-right">
            <div className="leaflet-control leaflet-bar " style={{marginTop:"100px",marginRight: "10px",color:"white"}}><div onClickCapture={(e)=>{e.stopPropagation(); userPos && mapRef.current?.panTo(userPos)}}><img src={locationicon} width="30px" height="30px" style={{filter:"invert(1)"}}></img></div></div>
        </div>
    </MapContainer>
    
}


const MarkerLayer = memo(({points}:{points:iPoint[]}) =>  <LayerGroup></LayerGroup>)

export {Map}
export type {iPoint}