
import { LatLngTuple } from "leaflet";
import { getFilename, getJSONfile } from "./dataaccess";
import { iInventoryAction } from "./inventory";




interface iGamePoint {
    id :string,
    name: string,
    localisation: LatLngTuple,
    description: string,
    type: string,
    picture?:string
    thumbnail?:string
}

interface iPointDialogues {
    avatar?: string,
    items?: iGameItem[],
    greetings_before_items?:string,
    dialogue: DialoguesObject
}

type DialoguesObject = {[key : string] : iDialoguePart}

interface iDialoguePart {
    body?: string,
    img?: string,
    flashimg?: string,
    options?: string[],
    actions?: ActionsObject
    onlyonce?: boolean
}

type ActionsObject =   {[key:string]   : ()=>[string,iDialoguePart,iInventoryAction | undefined]}

interface iGameItem {
    id: string,
    picture: string,
    description?:string,
    name?:string,
    isCompleted?: boolean,
    chooseOptionText: string,
    chooseOptionReply: string,
    returnOptionText: string,
    returnOptionReply: string,
    returnPicture?:string,
}

interface iGameAvatar {
    url: string,
    name?:string
}



async function getGameLocalisations(gameName:string, lang:string): Promise<iGamePoint[] | null> {
    const filename = await getFilename(gameName,lang,'points.json')

    if(!filename) return null;
    return getJSONfile(filename)
}


const globalPointDialogueCache : {[key:string] : any} = {}

function findItemFromId(id:string, items:iGameItem[]){
    return items.find(item => item.id === id)
}


async function getPointDialogues(gameName:string, lang:string, pointId:string): Promise<iPointDialogues | null>{
    const cacheKey = `${gameName}_${lang}_${pointId}`
    if(globalPointDialogueCache[cacheKey]) return globalPointDialogueCache[cacheKey];

    const filename = await getFilename(gameName,lang,pointId+'.json','points')
    
    if(!filename) return null;

    const pointData = await getJSONfile(filename);

    if(pointData === null) return null;

    if(!pointData?.items?.length) {
        return globalPointDialogueCache[cacheKey] = pointData
    }

    const gameItems = await getGameItems(gameName,lang);

    if(gameItems === null) return null;

    pointData.items = pointData.items.map((itemid : string) => findItemFromId(itemid,gameItems))

    return globalPointDialogueCache[cacheKey] = pointData
}

const getFakeDialoguesFor = (pointName:string) : DialoguesObject => {

    const dialogueTemplate = {
        "###START###" : {
            body: `Jesteś w ${pointName} Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
            options: [
                "first option",
                "second option"
            ]
        },
        "first option": {
            body: "XXX Answer to first option. Etc. etc",
            options: [
                "third option",
                "second option"
            ]
        },
        "second option": {
            body: "Answer to second option lorem ipsum, terefere",
            options: [
                "third option",
                "first option"
            ]
        },
        "third option": {
            body: "This is third and a final option, nothing more will happen to you",
            options: [
                "first option",
                "second option"
            ]
        }
    }

    return dialogueTemplate;
}


async function getGameItems(gameName:string, lang:string): Promise<iGameItem[] | null>{
    const filename = await getFilename(gameName,lang,'items.json')

    if(!filename) return null;
    return getJSONfile(filename)
}


async function getGameAvatars(gameName:string, lang:string): Promise<iGameAvatar[] | null>{

    return null;
}



export {
    getGameLocalisations,
    getPointDialogues,
    getGameItems,
    getGameAvatars,
    getFakeDialoguesFor,
    findItemFromId
}

export type {
    iGamePoint,
    iDialoguePart,
    iGameItem,
    iGameAvatar,
    DialoguesObject,
    ActionsObject,
}