import { useRef, useEffect, useState } from "react"

const useTimer = () => {
    type TimerOrNull = ReturnType<typeof setTimeout> | null 
    const timer = useRef<TimerOrNull>(null)

    const setTimer = (callback: ()=>void, delay:number) => {
        const callbackWrap = () => {
            //console.log('firing timer ', timer.current)
            callback()
        }
        timer.current = setTimeout(callbackWrap,delay)
    }

    useEffect(()=>()=>{
        //console.log('clearing timer ',timer.current)
        if(timer.current === null)return;
        clearTimeout(timer.current)
        timer.current = null
    },[])
    return setTimer;
} 

const useIsMounted = () => {

    let mounted = useRef(true)

    useEffect(()=>{
        mounted.current = true;
        return () => {mounted.current = false}
    })

    return ()=>mounted.current;
}

const useInitOnce = (initFunc : ()=>void ) => {

    let [initialized, setInitialized] = useState(false)
    let initRef = useRef(false)
    
    useEffect(
        () => {
            if(initRef.current) return;
            Promise.resolve(initFunc())
            return () => {setInitialized(initRef.current = true)}
        },
        []
    )
    return initialized;
}

export {useInitOnce, useTimer, useIsMounted}