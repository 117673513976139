import {useState, createContext, useContext, useCallback, ReactInstance, ReactNode } from "react"


interface iFullscreenData {
    src : string,
    align?: "top" | "bottom" | "center",
    description?: string,
    overlayText?: string,
    noAutoClose?:boolean,
    overlayOnEnd?:boolean,
    onClose?: ()=>void
}

function useFullscreenState(){
    return useState<string | iFullscreenData|  null>(null)
}

const FullscreenContext = createContext<ReturnType<typeof useFullscreenState> | null>(null);


const useFullscreen = () => {
    const fullscreen = useContext(FullscreenContext)
    if(fullscreen === null) throw Error("use <FullscreenProvider></FullscreenProvider> on the top level to use the Inventory")
    return fullscreen
} 


const VideoElement = ({src,onEnded}:{src:string,onEnded?:()=>void}) => {
    return <video autoPlay={true} muted={true} onEnded={onEnded} src={src} playsInline={true}></video>
    //playsinline=true
}

const FullscreenViever = () => {
    const [data,SetImgurl] = useFullscreen()
    const [showOverlay, setShowOverlay] = useState(false)
    
    if(data == null) return null;

    const {
        src:imgurl, 
        align = "center", 
        description = "", 
        overlayText = "",
        noAutoClose = false, 
        onClose = ()=>{},
        overlayOnEnd = false,
    } = typeof data == "string" ? {src:data} : data

    const closeme = () => {
        SetImgurl(null);
        onClose();
    }

    const onEnded = overlayOnEnd ? () => setShowOverlay(true)
    : noAutoClose ? undefined
    : closeme

    const overlayTextWithBreaks = overlayText.split("\\n").flatMap(l=>[l,<br/>])

    const shouldDisplayOverlay = (overlayText !=="") && (showOverlay || overlayOnEnd==false)
    
    //H.264-encoded MP4 files
    if(imgurl.endsWith('.webm') || imgurl.endsWith('.mp4')){
        return <div className="fullscreen"  onClick={closeme}>
            <VideoElement src={imgurl} onEnded={onEnded}></VideoElement>
            { shouldDisplayOverlay ? <div className="ImageOverlay"><div>{overlayTextWithBreaks}</div></div> : null }
        </div>
    }

    return <div className="fullscreen" onClick={closeme}>
        <img src={imgurl} style={{objectPosition:align}}></img>
        {description !=="" ? <div className="ImageDescription"><div>{description}</div></div> : null }
    </div>
}

const FullscreenProvider = ({children}:{children: ReactNode}) => {
    const fullscreen = useFullscreenState()
    return <FullscreenContext.Provider value={fullscreen}>{children}</FullscreenContext.Provider>
}

const useClickToFullscreen = () => {
    const [_, setFullscreen] = useFullscreen()
    return (item:{picture:string, description?:string}, ev?:any) => {
        ev && ev.preventDefault();
        setFullscreen({src:item.picture,align:"center",description:item.description})
    }
}

export {useFullscreen,FullscreenProvider,FullscreenViever,useClickToFullscreen}
