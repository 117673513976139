import { ReactNode, useState } from "react"
import { useGeo } from "../utils/usegeo"

function GeolocationNeededModal({onClick}:{onClick?:()=>void}){

    const [buttonTitle, setButtonTitle] = useState('Dalej')
    const {geo, setGeo, startGeo} = useGeo()
  
    const checkGeoAccess = () => {
      setButtonTitle('Sprawdzam')
      startGeo()
      onClick && onClick()
    }
  
    return <OneButtonModal title ="Geolokalizacja" buttonTitle={buttonTitle} buttonClick={checkGeoAccess}>
      Do prawidłowego działania potrzebujemy dostęp do informacji o Twojej lokalizacji
    </OneButtonModal>
  }
  
function OneButtonModal({children,title,buttonTitle,buttonClick,outsideClick}:{
    children:ReactNode,
    title?:string,
    buttonTitle?:string,
    buttonClick?:()=>void,
    outsideClick?:()=>void
  }){
    return <Modal>
      {title ? <h2>{title}</h2> : null }
      <div>{children}</div>
      {
        buttonTitle ? <div className='Buttons'><RedButton text={buttonTitle} onClick={buttonClick ?? (()=>{})} /></div> : null
      }
    </Modal>
  }
  
  function Modal({children}:{children:ReactNode}){
    return <div className='ModalPanel'>
      <div className='ModalDialogue'>
        <div className='Content'>
         {children}
        </div>
      </div>
    </div>
  }
  
  
  const RedButton = ({text,onClick}:{text:string,onClick:()=>void}) => <JustButton onClick={onClick}>{text}</JustButton>
  const GrayButton = ({text,onClick}:{text:string,onClick:()=>void}) => <JustButton onClick={onClick} className='GrayButton'>{text}</JustButton>
  const JustButton = ({children,onClick,className="RedButton"}:{className?:string,children:ReactNode,onClick:()=>void}) => <div className={className} onClick={onClick}>{children}</div>

  export {GeolocationNeededModal, OneButtonModal, Modal, RedButton, GrayButton}