import React, { useEffect, useRef, useState } from 'react';
import MGW_PL_White  from './MGW_PL_White.png'
import './App.css';

import { latLngBounds, LatLngExpression, LatLngTuple, Map as MapClass } from 'leaflet';

import { getClosestPoints } from './utils/latlngutils';
import { useInitOnce } from './utils/utilhooks';

import { getGameLocalisations, iGuidePoint } from './utils/guideDataAccess';
import GeneralMapApp from './components/GeneralMapApp';
import { GuidePanel } from './components/GuidePanel';
import { useGeo } from './utils/usegeo';
import { GeolocationNeededModal } from './components/Modal';
import { GpsStatusBar } from './components/GpsStatusBar';
import { useMapBounds } from './utils/usemapbounds';
import { Link } from 'react-router-dom';
import { Rectangle } from 'react-leaflet';
import { ConfigProvider, useConfig } from './utils/useconfig';
import { getConfig } from './utils/dataaccess';


function AudioGuideApp() {
  const gameName = "audioprzewodnik"
  const gameLang = "PL"

  const center : LatLngTuple = [52.23311946855967, 20.9983312811346]

  const [userPos, setUserPos] = useState<LatLngTuple | undefined>(undefined)
  const [choosenPoint,setChoosenPoint] = useState<iGuidePoint | null>(null)
  const [closeToPoint, setCloseToPoint] = useState<ReturnType<typeof getClosestPoints>[number] | null>(null)
  const [points, setPoints] = useState<iGuidePoint[]>([])
  const [gameBounds, setGameBounds] = useState<L.LatLngBounds | null>(null)
  const [config, setConfig] = useConfig()
  const [showIntro, setShowIntro] = useState(true)
  
  const mapRef = useRef<MapClass | null>(null)
  const {geo} = useGeo()

  const mapBounds = useMapBounds(mapRef)

  const mapClickHandler = (ev:any) => {
    const latlngtuple = [ev.latlng.lat, ev.latlng.lng] as LatLngTuple
    const nearPoints = getClosestPoints(latlngtuple,points)
    const [closestPoint] = nearPoints;

    const selectedPoint = points.find(p=>p.id == closestPoint.id)

    setUserPos(ev.latlng)
    //console.log(ev.latlng, nearPoints.slice(0,-5).map(p=>p.id+' '+p.distance))
    if(closestPoint.distance < 30){ //zrobic wiecej? 50?
      setCloseToPoint(closestPoint)
      selectedPoint!==undefined && setChoosenPoint(selectedPoint)
      //console.log(selectedPoint)
    } else {
      setCloseToPoint(null)
    }
  }
  
  /*
  const statusBarClickHandler = () => {
    if(closeToPoint === null) return;
    setChoosenPoint(closeToPoint.id)
  }
  */

  const centerOnUserClickHandler = () => {
    if(mapRef.current === null || userPos === undefined)return;
    mapRef.current.panTo(userPos)
  }


  useInitOnce(
    async () => {
      const points = ((await getGameLocalisations(gameName,gameLang)) ?? []).map(point => ({...point,isAvailable:true}))
      setGameBounds(latLngBounds(points.map(p => p.localisation)))
      setPoints(points)
      setConfig((await getConfig(gameName,gameLang)) ?? {})
    }
  )

  const introPoint = {id:config?.intro?.id, name: config?.intro?.name, photo:config?.intro?.photo, mp3:config?.intro?.mp3}

  return (
    
    <GeneralMapApp points={points} center={center} userPos={geo.position} mapClickHandler={()=>{}} onMarkerClick={mapClickHandler} mapRefCallback={(ref:MapClass)=>mapRef.current = ref}>
        <React.Fragment>
          {
            geo.state === 'UNKNOWN' || geo.watcherId === undefined ? <GeolocationNeededModal /> : null
          }
          {
            showIntro && config?.intro ? <GuidePanel visible={true} point={introPoint} clickOutsideHandler={()=>setShowIntro(false)}></GuidePanel> : <div></div>
          }
          {
              choosenPoint!==null && !showIntro ? <GuidePanel visible={true} point={choosenPoint} clickOutsideHandler={()=>setChoosenPoint(null)}></GuidePanel> : <div></div>
          }
          <GpsStatusBar bounds={gameBounds} mapbounds={mapBounds} map={mapRef.current}></GpsStatusBar>
          <div className="GuideBottomBar">AUDIOPRZEWODNIK <Link to="/"><img src={MGW_PL_White}></img></Link></div>
        </React.Fragment>
    </GeneralMapApp>
    
  );
}

export default ()=><ConfigProvider><AudioGuideApp /></ConfigProvider>

//export default AudioGuideApp;


