import React, { useEffect, useState } from "react";
import { latLngBounds, LatLngExpression, LatLngTuple, Map as MapClass } from 'leaflet';

function useMapBounds(mapRef:React.MutableRefObject<MapClass | null>){
    const [mapBounds, setMapBounds] = useState<L.LatLngBounds | null>(null)
    const mapBoundsChange = () => {
    if(mapRef.current === null) return;
    setMapBounds(mapRef.current.getBounds())
    }

    const mapEventsObj = {
    load: mapBoundsChange,
    zoomend: mapBoundsChange,
    moveend: mapBoundsChange
    }

    useEffect(()=>{
    mapRef.current?.on(mapEventsObj)
    return ()=>{mapRef.current?.off(mapEventsObj)}
    },[mapRef.current])
    return mapBounds
}

export {useMapBounds}