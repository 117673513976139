import {createGlobalStore} from './createGlobalStore'


interface iInitialDataAction {
    type: "AVATAR" | "FIRST_VISIT",
    avatar?:string
  }
  
  interface iInitialState {
    avatar?:string,
    firstVisit?:boolean
  }

  const reducer = (state: iInitialState,action:iInitialDataAction):iInitialState =>{
    switch(action.type){
      case "AVATAR": return {...state,avatar:action.avatar ?? ""}
      case "FIRST_VISIT": return {...state,firstVisit:false}
    }
  }
  
  const initialState =  {
    avatar:"",
    firstVisit:true,
  }


const {Provider:InitialDataProvider, useStore:useInitialData, get, set, reset, notifySubscribers} = createGlobalStore(initialState,reducer,"gameInitialState")



export {InitialDataProvider,useInitialData, get, set, reset, notifySubscribers}
export type {iInitialDataAction}
