import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { ActionsObject, DialoguesObject, getFakeDialoguesFor, getPointDialogues, iDialoguePart, iGameItem, iGamePoint } from "../utils/dialogueDataAccess"
import {iConfig} from '../utils/dataaccess'
import { useIsMounted, useInitOnce, useTimer } from "../utils/utilhooks"
import { useInventory } from "../utils/inventory"
import { useClickToFullscreen, useFullscreen } from "./FullscreenPicture"
import { iPoint } from "./Map"
import { useConfig } from "../utils/useconfig"

interface iMsg {
    avatar?: string,
    me:boolean,
    msg:iDialoguePart
}

const ChatTopBar = ({children, onClick} : {children?: JSX.Element, onClick?:()=>void}) => {
    return <div className="ChatTopBar" onClick={onClick}>{children ?? null}</div>
}

const ChatContainer = ({children} : {children?: JSX.Element | JSX.Element[]} ) => {
    return <div className="ChatContainer">
        {children}
    </div>
} // <div style={{scrollSnapAlign:"end"}}></div>

const ChatMessage = ({message} : {
    message: iMsg
}) => {
    const {avatar, me:fromMe, msg : {body,img,flashimg}} = message

    //const [_,setFullscreen] = useFullscreen()
    const photoClickHandler = useClickToFullscreen()

    //console.log(message)
    return <div className="ChatMessage" style={{flexDirection: fromMe===true ? "row-reverse" : "row"}}>
        <div className="ChatMessageAvatar">
        { avatar ? <img src={avatar}></img> : null}
        </div>
        <div className="ChatMessageContent" style={{textAlign: fromMe===true ? "right" : "left"}}>
        { body ? <div>{body}</div> : null}
        { img ? <div className="imgContainer"><img src={img} onClick={(ev)=>photoClickHandler({picture:img},ev)}></img></div>: null}
        </div>
    </div>
}

const getDialogues = async (pointId:string) => (await getPointDialogues('powstanie','PL',pointId))?.dialogue ?? getFakeDialoguesFor(pointId)

const getDialoguePart =  (dialogues: DialoguesObject, question: string = "") : iDialoguePart => {
    question === "" && (question = '###START###')
    return dialogues[question] ?? {}
}

const prepareReturnActions = (itemToReturn : iGameItem) : ActionsObject=> {
    
    return {
        [itemToReturn.returnOptionText] : () => {
            //setInventory({type: "give", item: itemToReturn})
            return [
                itemToReturn.returnOptionText,
                {
                    body:itemToReturn.returnOptionReply,
                    img:undefined,
                    flashimg: itemToReturn.returnPicture ?? undefined
                },
                {
                    type: "give", 
                    item: itemToReturn
                }
            ]
        }
    }
}

const prepareTakeActions = (itemsOnPoint : iGameItem[], dialoguePart : Partial<iDialoguePart>,config:iConfig | null) : ActionsObject => {

    const mypart  = config?.dialogue_take_action_text //"Masz dla mnie jakieś zadanie?"

    return {
        [mypart] : () => {
            return [mypart,{
                body: config?.dialogue_take_action_response, //"Tak, mam coś do dostarczenia, wybierz",
                img: undefined,
                flashimg: undefined,
                options: [],
                actions : itemsOnPoint.reduce( (acc,item)=>({
                    ...acc,
                    [item.chooseOptionText ?? item.name]: () => [ //makeTakeAction(item)
                        item.chooseOptionText ?? "wybieram "+item?.name,
                        {
                            body:item.chooseOptionReply ?? "ok, przenieś ["+item?.name+"] do najblizszego punktu. Mozemy wrocic do rozmowy",
                            ...dialoguePart
                        },
                        {
                            type:"take",
                            item
                        }
                    ]
                }), {})
            },undefined]
        }
    }
}

const itemsFilter = (inventory : iGameItem[], itemsOnPoint : iGameItem[]) => {
    //const toTake = itemsOnPoint.filter(item => !inventory.some(invitem => invitem.id == item.id))
    const toTake = itemsOnPoint.some(item => inventory.some(invitem => item.id == invitem.id)) ? [] : itemsOnPoint

    const toReturn = inventory.filter(item=>!itemsOnPoint.some(pointitem => pointitem.id == item.id) && !item.isCompleted )
    return {
        toTake,
        toReturn,
        takeOrReturn : toTake.length || toReturn.length 
    }
}


const prepareActions = (items : ReturnType<typeof itemsFilter>, finalOptions : string [] = [],config:iConfig | null) : ActionsObject => {
    const closeAction : ActionsObject = {[config?.final_option_text ?? 'Ok, idę dalej'] : () => ["",{},undefined]}

    //const itemsToReturn = inventory.filter(item=>!itemsOnPoint.some(pointitem => pointitem.id == item.id) && !item.isCompleted )

    if(items.toReturn.length){
        return prepareReturnActions(items.toReturn[0])
    }

    //if(!itemsOnPoint.length) return {}

    //const canTakeItems = itemsOnPoint.filter(item => !inventory.some(invitem => invitem.id == item.id))   //itemsOnPoint.length && !inventory.some(item=>itemsOnPoint.some(pointitem => pointitem.id == item.id))

    if(items.toTake.length){// && itemsOnPoint.length == canTakeItems.length ){
        return prepareTakeActions(items.toTake,{
            img: undefined,
            flashimg: undefined,
            options: finalOptions,
            actions: finalOptions.length ? {} : closeAction
        },config)
    }

    if(!finalOptions.length){
        return closeAction 
    }

    return {}
}

const ChatMessageOptions = ({avatar,lastMessage,onChoice,closeWindowFunc,itemsOnPoint} : {
    avatar?: string,
    lastMessage: iMsg,
    onChoice: (mypart:string, npcpart? : iDialoguePart) => void,
    closeWindowFunc?: () => void
    itemsOnPoint:iGameItem[]
}) => {

    const [inventory, inventoryDispatch] = useInventory(store=>store);
    const [_,setFullscreen] = useFullscreen()
    const [config] = useConfig()

    const items = itemsFilter(inventory,itemsOnPoint)
    const options = lastMessage?.msg?.options ?? [];
    const actions = lastMessage?.msg?.actions ?? prepareActions(items, options, config)
    const isVisible = Object.keys(actions).length || options.length

    const actionClickHandler = (action:string) => {
        const [mypart,npcpart,inventoryAction] = actions[action]()
        if(mypart == "" && !Object.keys(npcpart).length && closeWindowFunc){
            return closeWindowFunc()
        }

        if(inventoryAction) inventoryDispatch(inventoryAction)

        const x = {...lastMessage.msg,...npcpart}
        console.log('action click handdler',x)
        if(x.flashimg)setFullscreen(x.flashimg)
        onChoice(mypart,x)
    }

    const actionsKeys = Object.keys(actions)

    return !isVisible ? null : <div className="ChatMessage" style={{flexDirection: "row-reverse" }}>
        <div className="ChatMessageAvatar">
        { avatar ? <img src={avatar}></img> : null}
        </div>
        <div className="ChatMessageContent" style={{textAlign:  "right" }}>
            {actionsKeys.length ? null : options.map(option => <div className="InMessageButton" key={option} onClick={()=>onChoice(option)}>{option}</div>)}
            {actionsKeys.map(action => <div className="InMessageButton action" key={action} onClick={()=>actionClickHandler(action)}>{action}</div>)}
        </div>
    </div>
}


const ChatPanel = ({point,meavatar,visible,clickOutsideHandler}:{
    point:iPoint,
    meavatar?:string,
    visible:boolean,
    clickOutsideHandler?:()=>void
}) => {

    const name = point.name
    let isVisible = visible
    let [isQuestionsVisible, setQuestionsVisible] = useState(true)
    let [dialogues,setDialogues] = useState<DialoguesObject | null>(null)
    let [messages, setMessages] = useState<iMsg[]>([])

    let [npcavatar, setNpcAvatar] = useState("")
    let [itemsOnPoint,setItemsOnPoint] = useState<iGameItem[]>([])
    const [inventory] = useInventory(store=>store);

    const isMounted = useIsMounted()
    const pushMessage = (msg:iMsg) => setMessages(messages => [...messages,msg])
    const meMessage = (option:string) => pushMessage({
        avatar:meavatar,
        me:true,
        msg:{
            body: option
        }
    });
    const npcMessage = (dialoguePart : iDialoguePart,avatar = npcavatar) => pushMessage({
        avatar, me: false, msg: dialoguePart
    })
    const lastMessage = () => messages[messages.length-1]

    const initialized = useInitOnce(
        async () => {
            const {avatar, items:itemsOnPoint, greetings_before_items} = (await getPointDialogues('powstanie','PL',point.id)) ?? {}
            const dialogues = await getDialogues(point.id)
            setDialogues(dialogues)

            // if(!isMounted()) return;
            //console.log('inited point ',point.id,items,avatar)
            avatar && setNpcAvatar(avatar);
            itemsOnPoint && setItemsOnPoint(itemsOnPoint);

            console.log('ChatPanel init ',greetings_before_items,inventory.length,itemsOnPoint?.length)

            /*
            if(greetings_before_items && itemsFilter(inventory,itemsOnPoint ?? []).takeOrReturn){
                return npcMessage({body:greetings_before_items}, avatar)
            }
            */

            const dialoguePart = getDialoguePart(dialogues);

            //npcMessage uzywa avatara ktory jest zapisywany w state, ale jego wartosc w state pojawi sie dopiero przy 
            //kolejnym renderze wiec musimy tu ja przekazac w parametrze zeby pierwsza wiadomosc nie byla pozbawiona avatara
            (dialoguePart.body || dialoguePart.img) && npcMessage(dialoguePart,avatar);
        }
    )


    let panelRef : any;
    const outsideClickHandler = (clickedEl:any) => {
        if(panelRef !== clickedEl.target) return;
        clickOutsideHandler && clickOutsideHandler()
    }

    const timer1 = useTimer()
    const timer2 = useTimer()

    const showDialoguePart = (dialoguePart : iDialoguePart) => {
        if(!isMounted() || !dialoguePart.body)return;
        const anyQuestions = dialoguePart?.options?.length || Object.keys(dialoguePart?.actions || {}).length
        timer1(()=>dialoguePart.body && isMounted() && npcMessage(dialoguePart),700)
        timer2(()=>isMounted() && setQuestionsVisible(true),900)
    }

    const say = (mypart:string, npcpart? : iDialoguePart) => {
        setQuestionsVisible(false);

        meMessage(mypart)
        if(npcpart !== undefined){
            return showDialoguePart(npcpart)
        }
        if(dialogues !== null){
            return showDialoguePart(getDialoguePart(dialogues,mypart))
        }

    } 


    return isVisible 
    ? <div className="ChatPanel" ref={(el:any)=>panelRef = el} onClick={outsideClickHandler}>
        <ChatTopBar onClick={clickOutsideHandler}>
            <span>PUNKT: <b>{name}</b></span>
        </ChatTopBar>
        <ChatContainer>
        <div>
            {
                messages.map((msg,linenr) => <ChatMessage key={linenr} message={msg}></ChatMessage>)
            }
        </div>
        <div>
            {
                isQuestionsVisible ? <ChatMessageOptions avatar={meavatar} key="end" lastMessage={lastMessage()} itemsOnPoint={itemsOnPoint} onChoice={say} closeWindowFunc={clickOutsideHandler}></ChatMessageOptions> : null
            }
        </div>
        </ChatContainer>

    </div> 
    : null;
} 

export {ChatPanel}