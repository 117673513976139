function saveObj(key:string, obj: Object){
    try {
      sessionStorage.setItem(key,JSON.stringify(obj))
      console.log('saveObj saved ',JSON.stringify(obj))
      return true;
    }
    catch(err){
      return false
    }
  }
  
  function readObj(key:string):Object | undefined{
    try {
      const val = sessionStorage.getItem(key)
      if(val === null) return undefined;
      const obj = JSON.parse(val)
      //console.log('readObj ',key,obj)
      return obj;
    }
    catch(err){
      console.log('readObj failed with error',key)
      return undefined
      
    }
    console.log('readObj nothing was read',key)
    return undefined;
  }

  export {saveObj,readObj}