import {createGlobalStore} from './createGlobalStore'

interface iPhotoItem {
    name?: string,
    description?: string,
    picture:string
}


interface iPhotoInventoryAction {
    type: "take" | "init" | "reset",
    item?: iPhotoItem 
}

const reducer = (state: iPhotoItem[], action: iPhotoInventoryAction ) : iPhotoItem[] => {
    //console.log(action)
    switch(action.type){
        case "reset":
            return [];
        case "init": 
            return state.length ? state : action.item ? [action.item] : [];
        case "take": 
            if(!action.item)return state;
            if(state.some(p=>p.picture === action?.item?.picture))return state;
            return [
                ...state,
                action.item
            ];
    }
}


const photoInventory : iPhotoItem[] = []

const {Provider:PhotoInventoryProvider, useStore:usePhotoInventory, get, set, reset, notifySubscribers} = createGlobalStore(photoInventory,reducer,"photoInventory")




export {usePhotoInventory,PhotoInventoryProvider, get, set, reset, notifySubscribers}
export type {iPhotoItem, iPhotoInventoryAction}