import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import './App.css';
import { BottomBar } from './components/BottomBar';
import { ChatPanel } from './components/ChatPanel';
import { latLngBounds, LatLngTuple, Map as MapClass } from 'leaflet';

import { getClosestPoints } from './utils/latlngutils';
import { useInitOnce } from './utils/utilhooks';

import { getGameLocalisations, getGameItems, iGamePoint, findItemFromId } from './utils/dialogueDataAccess';
import {getConfig} from './utils/dataaccess'
import {  InventoryProvider } from './utils/inventory';

import GeneralMapApp from './components/GeneralMapApp';


import { PhotoInventoryProvider, usePhotoInventory } from './utils/photoinventory';
import { Popup } from 'react-leaflet';
import { useFullscreen } from './components/FullscreenPicture';
import { ConfigProvider, useConfig } from './utils/useconfig';
import { useGeo } from './utils/usegeo';
import { GpsStatusBar } from './components/GpsStatusBar';
import { useMapBounds } from './utils/usemapbounds';

import { useWASDkeys } from './utils/useWASDkeys';
import { useInventoryFinale, usePhotoFinale } from './utils/usefinale';
import { InitialDataProvider, useInitialData } from './utils/useinitialdata';
import { IntroModalSequence } from './components/IntroModalSequence';


const gameName = "powstanie"
const gameLang = "PL"

//const center : LatLngTuple = [52.260763772822294, 20.90190523906131]
const center : LatLngTuple = [52.25184771386281, 20.9960406459868]


function ChatApp() {
  const [userPos, setUserPos] = useState(center)
  const [choosenPoint,setChoosenPoint] = useState("")
  const [closeToPoint, setCloseToPoint] = useState<ReturnType<typeof getClosestPoints>[number] | null>(null)

  const [points, setPoints] = useState<iGamePoint[]>([])
  const [config, setConfig] = useConfig()
  const [initialData,setInitialData] = useInitialData(store=>store)

  const [popupX,setPopupX] = useState<ReactNode | null>(null)

  const [_,setFullscreen] = useFullscreen()

  const [gameBounds, setGameBounds] = useState<L.LatLngBounds | null>(null)

  const mapRef = useRef<MapClass | null>(null)

  const finale = useInventoryFinale()

  let chatPanels : React.ReactNode = []
  let {geo} = useGeo()

  const mapBounds = useMapBounds(mapRef)

  useWASDkeys(mapRef,userPos,(pos)=>{setUserPos(pos);goToPos(pos)})

  const openClosestPoint = (pointId:string) => {
    const point = points.find(p => p.id === pointId)
    if(!point) return;
    //
    if(point?.type === 'photo'){
      setPopupX(<PopupX position={point.localisation}><MapPhotoPopup point={point}></MapPhotoPopup></PopupX>)
    } else {
      setPopupX(<PopupX position={point.localisation}><MapDialoguePopup point={point} onClick={()=>visitPoint(pointId)}></MapDialoguePopup></PopupX>)
    }
  }
   

  useEffect(()=>{
    console.log('Geo changed',geo)
    if(geo.state=="SUCCESS" && geo.position){
      setUserPos(geo.position)
      goToPos(geo.position)
    }
  },[geo])
  

  const hideChat = ()=> {
    //tutaj odpalamy outro jak mamy 8 odwiedzonych miejsc
    if(finale.isReady){
      finale.show()
    }

    setChoosenPoint("")
    setCloseToPoint(null)
  }

  const goToPos = (latlngtuple : LatLngTuple) => {
    
    if(!points.length) return;

    const closestPoint = getClosestPoints(latlngtuple,points)[0]
    console.log(latlngtuple,closestPoint)
    //console.log(latlngtuple)
    if(closestPoint.distance < 40){ //&& closestPoint.id!=closeToPoint?.id
      openClosestPoint(closestPoint.id)
      setCloseToPoint(closestPoint)
    } else {
      //setCloseToPoint(null) todo: czy to ma sens???
    }

    
    if(!mapBounds || !gameBounds) return;

    if(gameBounds.contains(latlngtuple) && !mapBounds.contains(latlngtuple)){
      mapRef.current?.panTo(latlngtuple)
    }

  }

  const mapClickHandler = (ev:any) => {
    const latlngtuple = [ev.latlng.lat, ev.latlng.lng] as LatLngTuple
    if(geo.state !== "ERROR") return;
    setUserPos(ev.latlng)
    goToPos(latlngtuple)
  }

  const visitPoint = (pointid:string) => {
    setChoosenPoint(pointid)
    finale.collectPoint(pointid)
  }

  const centerOnUserClickHandler = () => {
    if(mapRef.current === null)return;
   mapRef.current.panTo(userPos)
  }

  const initialized = useInitOnce(
    async () => {

      const points : iGamePoint[] = (await getGameLocalisations(gameName,gameLang)) ?? []

      setPoints(points)

      const items = (await getGameItems(gameName,gameLang)) ?? []
      const config = (await getConfig(gameName,gameLang)) ?? []

      typeof config?.first_item == "string" && (config.first_item = {...findItemFromId(config.first_item,items)})
      config.loaded = true;

      setGameBounds(latLngBounds(points.map(p => p.localisation)))

      setConfig(config)

    }
  )

  chatPanels = (points as iGamePoint[]).filter(p=>p.type!=='photo').map(point=>
    <ChatPanel meavatar={initialData.avatar} point={point} key={point.id} visible={choosenPoint==point.id} clickOutsideHandler={hideChat}></ChatPanel>
    )

  //console.log('ChatApp render')
  return  ( 
      <GeneralMapApp points={points} center={center} userPos={userPos} mapClickHandler={mapClickHandler} mapRefCallback={(ref:MapClass)=>mapRef.current = ref} mapChildren={popupX}>
        {config?.loaded ? <IntroModalSequence /> : null} 
        {chatPanels}
        <GpsStatusBar bounds={gameBounds} mapbounds={mapBounds} map={mapRef.current}></GpsStatusBar>
        <BottomBar avatar={initialData.avatar} onAvatarClick={centerOnUserClickHandler}></BottomBar>
      </GeneralMapApp>
  ) ;
}

function MapPhotoPopup({point}:{point:iGamePoint}){
  const [isPhotoCollected,dispatch] = usePhotoInventory(store => store.find(item => item.picture == point.picture))  
  const [_,setFullscreen] = useFullscreen()
  const [config] = useConfig()
  const photoFinale = usePhotoFinale()

  const collectPhotoHandler = () => {
    dispatch({type:"take",item:{picture:point.picture ?? "",description:point.description}})
    if(photoFinale.isReady){
      photoFinale.show()
    } else {
      config?.photo_inventory_on_added_movie && setFullscreen(config.photo_inventory_on_added_movie)
    }
  }
  return isPhotoCollected !== undefined
  ? <div className='MapPopup'><img src={point.picture}></img><div>{config?.popup_collected_photo_text}</div></div>
  : <div className='MapPopup' onClick={collectPhotoHandler}><img src={point.picture}></img><div>{config?.popup_new_photo_text}</div></div>
}

function MapDialoguePopup({point,onClick}:{point:iGamePoint,onClick:()=>void}){
  const [config] = useConfig()
  const clickHandler = () => {
    onClick();
    //map.closePopup()
  }
  return  <div className='MapPopup' onClick={clickHandler}><img src={point.picture}></img><div>{config?.popup_dialogue_new_message}</div></div>
}


function PopupX({position,children}:{position:LatLngTuple,children:ReactNode}){
  return <Popup  position={position} closeButton={false} closeOnClick={false} closeOnEscapeKey={false} autoClose={false}>{children}</Popup>
}


const ChatAppWithInventories = () => 
  <ConfigProvider>
    <InitialDataProvider>
    <InventoryProvider>
      <PhotoInventoryProvider>
        <ChatApp/>
      </PhotoInventoryProvider>
    </InventoryProvider>
    </InitialDataProvider>
  </ConfigProvider>



export default ChatAppWithInventories;


