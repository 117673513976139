import { iGameItem } from "./dialogueDataAccess"

import {createGlobalStore} from './createGlobalStore'

interface iInventoryAction {
    type: "take" | "give" | "init" | "reset",
    item?: iGameItem 
}


const reducer = (state: iGameItem[], action: iInventoryAction ) : iGameItem[] => {
    console.log(action)
    switch(action.type){
        case "reset":
            return [];
        case "init": 
            return state.length ? state : action.item ? [action.item] : [];
        case "take": 
            return action.item ? [
                ...state,
                action.item
            ] : state;
        case "give":
            return state.map(
                item => item.id == action?.item?.id ? {...item,isCompleted:true} : item
            );
    }
}

const Inventory : iGameItem[] = []

const {Provider:InventoryProvider, useStore:useInventory, get, set, reset, notifySubscribers} = createGlobalStore(Inventory,reducer,"gameInventory")



export {useInventory,InventoryProvider, get, set, reset, notifySubscribers}
export type {iInventoryAction}