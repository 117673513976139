import { Link, useNavigate } from 'react-router-dom';
import './App.css';
import Logo from './components/Logo';
import {reset as resetInventory,get as getInventory} from './utils/inventory'
import {reset as resetPhotos} from './utils/photoinventory'
import {reset as resetInitialData} from './utils/useinitialdata'

const FirstPage = () => {
    
    const navigate = useNavigate();
    
    const isGameInProgress = () => getInventory().length > 0

    const resetStorage = (redirectTo:string) => {
        sessionStorage.clear()
        //resetInventory({type:"reset"})
        //resetPhotos({type:"reset"})
        resetInventory()
        resetPhotos()
        resetInitialData()
        navigate(redirectTo)
    }


    return <div className="FirstPage">
        <div><Logo></Logo></div>
        <div className='content'>
            <div>Gra Śladami powstania w Getcie Warszawskim</div>
            <div> {isGameInProgress() ? <Link to={`/powstanie`}>KONTYNUUJ / </Link>: null} <a onClick={()=>resetStorage('/powstanie')}>Nowa gra</a> </div>
            <div className='divider'></div>
            <div><Link to={`/przewodnik`}>Audioprzewodnik</Link></div>
            <div className='divider'></div>
            <div><a href='https://1943.pl' target={"_blank"}>Strona www muzeum</a></div>
        </div>
        <div>polityka prywatnosci</div>
    </div>
}

export {FirstPage}